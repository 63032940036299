<template>
  <div>
    <v-text-field
      dense
      outlined
      counter
      @keypress.enter="focus('.type input')"
      :label="$t('data.title')"
      :error="!!apiError.title"
      :error-messages="apiError.title"
      autofocus
      maxlength="100"
      v-model="formData.title"
    ></v-text-field>

    <v-select
      dense
      outlined
      counter
      class="type"
      :items="types"
      :label="$t('video.data.type')"
      :error="!!apiError.type"
      :error-messages="apiError.type"
      maxlength="100"
      v-model="formData.type"
    ></v-select>
  </div>
</template>

<script lang="babel" type="text/babel">
import listCreatePopupMixin from '@/components/list/listCreateButton/listCreatePopupMixin'
export default {
  mixins: [listCreatePopupMixin],
  data: () => ({
    formData: {
      title: null,
      type: 'youtube',
    },
  }),
  computed: {
    types() {
      return this.$videoTypes.map(type => ({
        text: this.$t(`video.data.type.${type}`),
        value: type,
      }))
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
